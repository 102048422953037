export default [
  {
    name: 'alpaca'
  },
  {
    name: 'arigatou'
  },
  {
    name: 'arigatou2'
  },
  {
    name: 'arigatou3'
  },
  {
    name: 'arigatou4'
  },
  {
    name: 'bat'
  },
  {
    name: 'bat2'
  },
  {
    name: 'bear_cry'
  },
  {
      name: 'bear_cry2'
  },
  {
      name: 'bear_cry3'
  },
  {
      name: 'bear_cry4'
  },
  {
      name: 'bear_cry5'
  },
  {
      name: 'bear_cry6'
  },
  {
      name: 'bear_heart'
  },
  {
      name: 'bear_wave'
  },
  {
      name: 'bear'
  },
  {
      name: 'bear2'
  },
  {
      name: 'bear3'
  },
  {
      name: 'bear4'
  },
  {
      name: 'bee'
  },
  {
      name: 'bee2'
  },
  {
      name: 'blush'
  },
  {
      name: 'blush2'
  },
  {
      name: 'blush3'
  },
  {
      name: 'book',
      ext: 'png'
  },
  {
      name: 'book2'
  },
  {
      name: 'bw'
  },
  {
      name: 'bw2'
  },
  {
      name: 'cat'
  },
  {
      name: 'cat2'
  },
  {
      name: 'cat3'
  },
  {
      name: 'cheer'
  },
  {
      name: 'cheer2'
  },
  {
      name: 'cheer3'
  },
  {
      name: 'cheer4'
  },
  {
      name: 'confuse'
  },
  {
      name: 'confuse2'
  },
  {
      name: 'cry'
  },
  {
      name: 'cry2'
  },
  {
      name: 'doushite'
  },
  {
      name: 'eye'
  },
  {
      name: 'ghost'
  },
  {
      name: 'ghost2'
  },
  {
      name: 'ghost3'
  },
  {
      name: 'ghost4'
  },
  {
      name: 'ghost5'
  },
  {
      name: 'ghost6'
  },
  {
      name: 'ghost7'
  },
  {
      name: 'ghost8'
  },
  {
      name: 'ghost9'
  },
  {
      name: 'ghost10'
  },
  {
      name: 'ghost11'
  },
  {
      name: 'ghost12'
  },
  {
      name: 'ghost13'
  },
  {
      name: 'ghost14'
  },
  {
      name: 'ghost15'
  },
  {
      name: 'ghost16'
  },
  {
      name: 'ghost17'
  },
  {
      name: 'ghost18'
  },
  {
      name: 'ghost19'
  },
  {
      name: 'giftbox'
  },
  {
      name: 'gomena'
  },
  {
      name: 'gomena2'
  },
  {
      name: 'gomena3'
  },
  {
      name: 'happy'
  },
  {
      name: 'happy2'
  },
  {
      name: 'happy_halloween'
  },
  {
      name: 'happy_halloween2'
  },
  {
      name: 'happy_halloween3'
  },
  {
      name: 'happy_halloween4'
  },
  {
      name: 'haunted'
  },
  {
      name: 'heart'
  },
  {
      name: 'heart2'
  },
  {
      name: 'heart3'
  },
  {
      name: 'heart4'
  },
  {
      name: 'heart5'
  },
  {
      name: 'heart6'
  },
  {
      name: 'heart7'
  },
  {
      name: 'heart8'
  },
  {
      name: 'heart9'
  },
  {
      name: 'heart10'
  },
  {
      name: 'heart11'
  },
  {
      name: 'heart12'
  },
  {
      name: 'heart13'
  },
  {
      name: 'heart14'
  },
  {
      name: 'heart15'
  },
  {
      name: 'heart16'
  },
  {
      name: 'heart17'
  },
  {
      name: 'heart18'
  },
  {
      name: 'heart19'
  },
  {
      name: 'heart20'
  },
  {
      name: 'heart21'
  },
  {
      name: 'heart22'
  },
  {
      name: 'heart23'
  },
  {
      name: 'heart24'
  },
  {
      name: 'heart25'
  },
  {
      name: 'heartbreak'
  },
  {
      name: 'heartbreak2'
  },
  {
      name: 'heartbreak3'
  },
  {
      name: 'hearts'
  },
  {
      name: 'hearts2'
  },
  {
      name: 'hearts3'
  },
  {
      name: 'hearts4'
  },
  {
      name: 'hearts5'
  },
  {
      name: 'hearts6'
  },
  {
      name: 'hearts7'
  },
  {
      name: 'hearts8'
  },
  {
      name: 'hearts9'
  },
  {
      name: 'hey'
  },
  {
      name: 'hide'
  },
  {
      name: 'hug'
  },
  {
      name: 'icon-heart'
  },
  {
      name: 'icon-heart2'
  },
  {
      name: 'icon-heart3'
  },
  {
      name: 'icon-heart4'
  },
  {
      name: 'icon-heart5'
  },
  {
      name: 'jacko'
  },
  {
      name: 'jacko2'
  },
  {
      name: 'jacko3'
  },
  {
      name: 'jacko4'
  },
  {
      name: 'lay'
  },
  {
      name: 'lay2'
  },
  {
      name: 'line'
  },
  {
      name: 'love'
  },
  {
      name: 'love2'
  },
  {
      name: 'love3'
  },
  {
      name: 'love4'
  },
  {
      name: 'monster'
  },
  {
      name: 'moon'
  },
  {
      name: 'music'
  },
  {
      name: 'omedetou'
  },
  {
      name: 'oyasumi'
  },
  {
      name: 'pepper'
  },
  {
      name: 'point'
  },
  {
      name: 'point2'
  },
  {
      name: 'point3'
  },
  {
      name: 'rain'
  },
  {
      name: 'ribbon'
  },
  {
      name: 'ribbon2'
  },
  {
      name: 'ribbon3'
  },
  {
      name: 'ribbon4'
  },
  {
      name: 'run'
  },
  {
      name: 'sad'
  },
  {
      name: 'sad2'
  },
  {
      name: 'sad3'
  },
  {
      name: 'scream'
  },
  {
      name: 'scream2'
  },
  {
      name: 'see_you'
  },
  {
      name: 'smile'
  },
  {
      name: 'sparkle'
  },
  {
      name: 'sparkle2'
  },
  {
      name: 'sparkle3'
  },
  {
      name: 'star'
  },
  {
      name: 'star2'
  },
  {
      name: 'star3'
  },
  {
      name: 'star4'
  },
  {
      name: 'star5'
  },
  {
      name: 'star6'
  },
  {
      name: 'star7'
  },
  {
      name: 'star8'
  },
  {
      name: 'star9'
  },
  {
      name: 'star10'
  },
  {
      name: 'star11'
  },
  {
      name: 'strawberry'
  },
  {
      name: 'tear'
  },
  {
      name: 'thank_you'
  },
  {
      name: 'uru'
  },
  {
      name: 'usagi'
  },
  {
      name: 'usagi2'
  },
  {
      name: 'usagi3'
  },
  {
      name: 'usagi4'
  },
  {
      name: 'usagi5'
  },
  {
      name: 'usagi6'
  },
  {
      name: 'usagi7'
  },
  {
      name: 'wave'
  },
  {
      name: 'wave2'
  },
  {
      name: 'wave3'
  },
  {
      name: 'wave4'
  },
  {
      name: 'weh'
  },
  {
      name: 'weh2'
  },
  {
      name: 'weh3'
  },
  {
      name: 'weh4'
  },
  {
      name: 'weh5'
  },
  {
      name: 'weh6'
  },
  {
      name: 'weh7'
  },
  {
      name: 'xoxo'
  },
  {
      name: 'xoxo2'
  },
]